import trans from '@common/i18n/v1/trans'

import init     from '../../init'
import storage  from '../../storage'

import styles from './Status.scss'

/**
 * Az aktuális státusza a felhasználónak arról, hogy be van-e jelentkezve vagy nincs.
 * Bejelentkezett állapotban kijelentkező gombként funkcionál.
 */
export default function Status (): HTMLElement {
  /* eslint-disable unicorn/consistent-function-scoping */
  /** Kijelentkezés. */
  function onClickSignOut (): void {
    storage.remove('jwt')

    // Majd kvázi újrainicializáljuk.
    init()
  }
  /* eslint-enable unicorn/consistent-function-scoping */

  const status: HTMLElement = (
    <div className={ styles.status }>
      <span className={ styles.signedOut }>{ trans('Status.signedOut') }</span>
      <span className={ styles.signedIn } onClick={ onClickSignOut }>{ trans('Status.signedIn') }</span>
    </div>
  )

  return status
}
