/* eslint-disable unicorn/consistent-function-scoping */
import trans from '@common/i18n/v1/trans'

import { EHttpStatusCode } from '@common/http/definitions'

import init from '../../init'

import storage from '../../storage'

import HelpPanel from '../HelpPanel/HelpPanel'
import FormTitle from '../FormTitle/FormTitle'
import Button    from '../Button/Button'
import Error     from '../Error/Error'
import Input     from '../Input/Input'
import Form      from '../Form/Form'
import Owl       from '../Owl/Owl'

import styles from './LoginForm.css'

/** Bejelentkezéshez szükséges komponens factory-ja. */
export default function LoginForm (): HTMLElement {
  const errorEl: HTMLSpanElement = <Error />

  /**
   * Ha valami hiba történt az ajax közben, akkor hívódó callback.
   * @param statusCode - A válasz kódja.
   */
  function error (statusCode: number): void {
    if (statusCode === EHttpStatusCode.BadRequest) {
      errorEl.innerHTML = ''

      errorEl.appendChild(
        <Fragment>
          <span>{ trans('LoginForm.invalid') }</span>
          <HelpPanel />
        </Fragment>
      )
    }
    else {
      errorEl.innerHTML = trans('server.error')
    }
  }

  /**
   * A form sikeressége esetén meghívódó callback.
   * @param jwtToken - A kapott token.
   */
  function success (jwtToken: string): void {
    storage.set('jwt', jwtToken)

    init()
  }

  const loginForm: HTMLFormElement = (
    <Form
      action="/api/playground/belepes"
      error={ error }
      success={ success }
    >
      <div className={ styles.owlContainer }>
        <Owl type="welcome" />
      </div>

      <FormTitle text={ trans('LoginForm.title') } />
      <p className={ styles.desc }>{ trans('LoginForm.desc') }</p>
      <Input name="username" required placeholder={ trans('LoginForm.username') } type="text" />
      <Input name="password" required placeholder={ trans('LoginForm.password') } type="password" />
      <Button text={ trans('LoginForm.submit') } />
      { errorEl }
    </Form>
  )

  return loginForm
}
