import { baseGlobals } from '@root/typescript/baseGlobals'

import { is } from '@common/is/is'

import { Query } from '@common/Query/Query'

import AppImage from '../AppImage/AppImage'
import AppTitle from '../AppTitle/AppTitle'
import DemoSign from '../DemoSign/DemoSign'

import storage from '../../storage'

import styles from './Card.scss'

const { host, protocol, search } = window.location
const BASE = (baseGlobals.isProd ? `${ protocol }//${ host }` : 'https://jatekter.ugyesedni.hu')

const query = Query.decode(search)

interface IProps {
  readonly title:            string
  readonly serial:           string
  readonly isDemo?:          boolean | undefined
  readonly isVisibleSerial?: boolean
  readonly href?:            string
}

/**
 * Egy app kártyájának factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Card (props: IProps): HTMLElement {
  const { isDemo, isVisibleSerial, serial, title } = props
  const hasDemo = is.boolean(isDemo)

  const baseHref = [
    `${ BASE }/apps/${ serial }/`,
    `?t=${ storage.get('jwt') ?? '' }`,
    is.string(query.lang) ? `&lang=${ query.lang }` : ''
  ].join('')

  const href = props.href ?? baseHref

  return (
    <div className={ [ styles.card, hasDemo && isDemo ? styles.demo : '' ] } data-id={ serial }>
      { isDemo ? <DemoSign /> : null }
      {   }

      <a
        className={ styles.link }
        href={ href }
        rel="noopener noreferrer"
        target="_blank"
      >
        <AppImage serial={ serial } size="medium" src="https://jatekter.ugyesedni.hu/images" />
        <AppTitle title={ title } />

        {
          isVisibleSerial
            ? <h6 className={ styles.serial } title="Szériaszám">{ serial }</h6>
            : null
        }
      </a>
    </div>
  )
}
