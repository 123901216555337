import trans from '@common/i18n/v1/trans'

import Link from '../Link/Link'

import Column from '../Column/Column'

import adatkezelesi from './pdf/adatkezelesi-tajekoztato.pdf'
import aszf from './pdf/aszf.pdf'

import styles from './Footer.scss'

/**
 * A játéktér lábléc elemének factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Footer (): HTMLElement {
  return (
    <footer className={ styles.footer }>
      <div className="container">
        <div className={ [ 'row', 'center-xs', styles.information ] }>
          <Column title={ trans('Footer.contact') }>
            <div className={ styles.contactRow }>
              <div className={ styles.icon } data-type="mail" />
              <Link href={ `mailto:${ trans('Footer.email') }` }>{ trans('Footer.email') }</Link>
            </div>

            <div className={ styles.contactRow }>
              <div className={ styles.icon } data-type="place" />
              { trans('Footer.address') }
            </div>

            <div className={ styles.contactRow }>
              <div className={ styles.icon } data-type="phone" />
              <Link href={ `tel:${ trans('Footer.telephone') }` }>{ trans('Footer.telephone') }</Link>
            </div>
          </Column>

          <Column title={ trans('Footer.shopping') }>
            <div dangerouslySetInnerHTML={ { __html: trans('Footer.webshop') } } />
          </Column>

          <Column title={ trans('Footer.information') }>
            <Link href={ aszf }>{ trans('Footer.terms') }</Link>
            <Link href={ adatkezelesi }>{ trans('Footer.policy') }</Link>
          </Column>
        </div>
      </div>

      <div className={ styles.dateRow }>
        <div className={ styles.dateRowLeft }>
          @
          { (new Date()).getFullYear() }
        </div>

        <div className={ styles.dateRowRight }>{ document.title }</div>
      </div>
    </footer>
  )
}
