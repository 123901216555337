import { is } from '@common/is/is'

import ajax from '@common/ajax/v2/ajax'

import { URL } from '@common/URL/URL'

import FallbackMessage from './components/FallbackMessage/FallbackMessage'

import type { IInitResponse } from './definitions'

import signIn   from './signIn'
import signOut  from './signOut'

import storage from './storage'

import User from './User'

declare const __DEV__: boolean

const FALLBACK_DATA = __DEV__ ? require('./fallbackData.json') : []

/** A struktúra felépülése után az init felszól a szervernek az adatokért. */
export default async function init (): Promise<void> {
  const url = `${ __DEV__ ? 'http://localhost:3100' : '' }/api/playground/init${ window.location.search }`

  const jwtToken = storage.get('jwt')

  const { isSuccess, result } = await ajax(
    URL.make(url, { platform: 'legacy' }),
    { authorization: `Bearer ${ jwtToken }` }
  )

  if (!isSuccess) {
    signOut(FALLBACK_DATA)

    return
  }

  const res = result as IInitResponse

  if (__DEV__ && !is.object(res)) {
    document.body.appendChild(<FallbackMessage />)
  }

  const {
    attachedApps = [],
    dailycode, // FALLBACK_DATA.map((app) => app.id)
    data = FALLBACK_DATA,
    isLoggedIn,
    theme = 'default'
  } = res ?? {}

  // Ha a szerver valamiért üres stringet küldene.
  const normalTheme = is.empty(theme.trim()) ? 'default' : theme

  User.isLoggedIn = isLoggedIn
  User.dailyCode  = dailycode
  User.theme      = normalTheme

  if (isLoggedIn) {
    signIn(data, attachedApps, dailycode, normalTheme)
  }
  else {
    signOut(data)
  }
}
